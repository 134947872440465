.Outer {
  height: var(--website-header-height);
  width: 200px;
  border-radius: var(--border-radius);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto calc(var(--website-header-height) - 33px);
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .Outer {
    margin: 0 auto;
  }
}
