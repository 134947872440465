.SideMenuWraper {
  position: fixed;
  width: var(--admin-side-menu-width);
  max-width: var(--admin-max-width);
  height: calc(100% - 62px);
  padding: 16px 0;
  min-height: calc(100vh - 100px);
  left: 0;
  top: var(--website-header-height);
  z-index: 7;
  background-color: white;
  box-sizing: border-box;
  border-right: var(--border-discret);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}
.Open {
  transform: translateX(0);
}
.Close {
  transform: translateX(-110%);
}

.SideMenu {
  overflow-y: scroll;
  height: calc(100vh - 200px);
}
.SideMenu {
  /* for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* for Chrome, Safari and Opera */
.SideMenu::-webkit-scrollbar {
  display: none;
}

.LogoOuter {
  width: 100%;
  height: 50px;
  text-align: center;
  padding: 0;
  margin: 0;
}
.SideMenuSubstitute {
  padding: 5px 12px;
  color: var(--text-2);
  border: 1.5px solid transparent;
  border-radius: var(--border-radius);
  margin: 2px;
  cursor: pointer;
  text-align: left;
  width: var(--side-menu-item-width);
  margin: 0 auto;
  word-break: break-all;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.SideMenuSubstitute:hover {
  color: var(--text-4);
  border-color: var(--border-3);
}
.ImageOuter {
  width: 27px;
  height: 27px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SideMenuSubstitute > .ImageOuter {
  width: 23px;
  height: 23px;
}
.ImageOuter > img {
  width: 100%;
  height: 100%;
}
.MenuLabel {
  color: var(--text-3);
  /* width: 110px; */
}

.LanguageSelectOuter {
  width: var(--side-menu-item-width);
  margin: 1px auto 16px auto;
}

.Innactive {
  height: 0;
}
.Active > a {
  background-color: var(--background-color-1);
  border: 1px solid var(--text-link-hover);
}
.Active > a > .MenuLabel {
  color: var(--text-4) !important;
}
.Backdrop {
  display: none;
}
.MenuToggle {
  display: none;

  width: 30px;
  height: 40px;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 2px;
}
.MenuToggle > div {
  width: 90%;
  height: 3px;
  margin: 3px 0;
  background-color: var(--text-1);
}

:root {
  --side-menu-item-width: calc(100% - 32px);
}

@media screen and (max-width: 900px) {
  .SideMenuWraper {
    position: fixed;
    min-height: calc(100vh - 78px);
    height: calc(100vh - 78px);
    padding: 14px 0;
    top: 12px;
    border: none;
    border-top-right-radius: var(--border-radius-big);
    border-bottom-right-radius: var(--border-radius-big);
    box-shadow: var(--box-shadow-spf);
    z-index: 999;
  }
  .Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    display: inline-block;
    background: var(--popup-background);
  }
  .MenuToggle {
    display: flex;
    position: absolute;
  }
  .SideMenu {
    height: calc(100vh - 247px);
  }
  .SideMenuSubstitute {
    margin: 4px auto 0 auto;
    padding: 10px 0;
    justify-content: center;
    border: 1px solid var(--background-color-2);
    background: var(--background-color-0);
  }
}
