.Outer {
  width: 100%;
  margin: 0 auto;
}
.RouteOuter {
  width: 100%;
}
.RouteOuter > .Middle > .ContentInner {
  grid-template-columns: auto 1fr auto;
}
.RouteOuter > .Middle > .ContentInner > .Column:nth-child(1),
.RouteOuter > .Middle > .ContentInner > .Column:nth-child(3) {
  cursor: pointer;
}
.RouteOuter > .HeaderInner {
  cursor: pointer;
}
.HeaderInner {
  border-radius: var(--border-radius);
  background-color: var(--background-color-3);
  border: 2px solid var(--background-color-4);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 7px;
}
.Middle {
  padding: 10px 0;
}
.ContentInner {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.Column {
  border-radius: var(--border-radius);
  background-color: var(--background-color-3);
  border: 2px solid var(--background-color-4);
  padding: 7px;
  width: 100%;
}
.LabelRoutes {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
}
.LabelCancel {
  font-size: var(--fs-small);
  text-align: center;
  padding-top: 7px;
}

@media screen and (max-width: 900px) {
  .Outer {
    width: calc(100vw - 32px);
  }
}
