.NavItem {
  width: 240px;
}
.NavItem > a {
  text-decoration: none;
}
.NavItem > a > div {
  width: var(--side-menu-item-width);
  margin: 1px auto 2px auto;
  padding: 8px 8px 8px 11px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
}
.NavItem > a > div > p {
  padding: 0;
  color: var(--text-2);
  text-align: left;
  word-break: normal;
  width: calc(100% - 40px);
}
.NavItem > a > div.Active {
  background-color: white;
  border: 1px solid var(--text-link-hover);
}
.NavItem > a > div.Active > p {
  color: var(--text-4);
}

.NavItem > a > div:hover {
  background-color: var(--background-color-0);
  border: 1px solid var(--text-link-hover);
}

.ImageOuter {
  width: 27px;
  height: 27px;
  margin: 0 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ImageOuter > img {
  width: 100%;
}
.ImageOuterSub {
  width: 4px;
  height: 16px;
  margin: 0;
}

@media screen and (max-width: 900px) {
  .NavItem > a > div {
    width: var(--side-menu-item-width);
    margin: 1px auto 2px auto;
    padding: 10px 12px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: var(--border-radius);
    border: 1px solid var(--background-color-2);
    background-color: var(--background-color-0);
  }
  .NavItem > a > div:hover {
    border: 1px solid var(--background-color-2);
    background-color: var(--background-color-0);
  }
}
