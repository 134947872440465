.Outer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  background: var(--popup-background);

  z-index: 999;
}
.Inner {
  max-height: 67% !important;
  height: auto;
  overflow-y: auto;
  position: absolute;
  margin: 0 auto;
  padding: 21px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #fff;
  box-shadow: var(--box-shadow-06);

  width: 300px;
  box-sizing: border-box;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  transition: 150ms all;
}
.Inner > img {
  width: 25%;
  height: auto;
  margin: 16px auto;
}
.Inner > p {
  font-size: var(--fs-3);
}

.InnerOnlyAnimation {
  width: 130px;
  height: 130px;
  position: absolute;
  margin: 0 auto;
  /* border-radius: 50%; */
  display: flex;
  flex-direction: column;
  /* background: rgba(247, 247, 247, 0.05); */
  top: calc(41% - 65px);
  left: calc(50% - 65px);
}

@media screen and (max-width: 900px) {
  .Inner {
    padding: 54px 16px 21px 16px;
    min-width: calc(100vw - 64px);
    max-width: calc(100vw - 64px);
    width: calc(100vw - 64px);
    top: 33px;
    left: 32px;
  }
  .Title {
    font-size: var(--fs-2);
    font-weight: 500;
    padding: 4px 0 0 16px;
  }
}
