.Outer {
  position: relative;
}
.SelectedValueOuter {
  cursor: pointer;
  display: grid;
  grid-template-columns: calc(100% - 28px) 27px;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-2);
  /* background-color: var(--background-color-0); */
}
.SelectedValueOuter:active {
  background-color: var(--background-color-input-focus);
  border-color: var(--text-2);
  box-shadow: var(--btn-box-shadow-active);
}
.SelectedValueOuter:hover {
  border-color: var(--text-link-hover);
  background-color: white;
}
.SelectedValueOuter:hover .SelectedValue {
  color: var(--text-4);
}
.SelectedValueOuter:hover .MarkerArrow {
  border-color: var(--text-4);
}
.SelectedValueOuterError {
  border-color: var(--text-error);
}
.SelectedValue {
  color: var(--text-2);
  padding: 7px 24px 7px 16px;
  border-radius: var(--border-radius);
  min-height: 1rem;
}
.SearchOuter {
  min-height: 1rem;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  height: calc(100% - 2px);
  width: 100%;
}
.SearchOuter > input {
  border: none;
  margin: 1px;
  height: 100%;
  background-color: inherit;
  border-color: inherit;
  padding-right: 30px;
  /* box-shadow: inherit; */
  /* background-color: antiquewhite; */
}
.MarkerArrowOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 27px;
  height: 100%;
}
.MarkerArrow {
  height: 8px;
  width: 8px;
  border-right: 1.5px solid var(--text-1);
  border-bottom: 1.5px solid var(--text-1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.Options {
  display: none;
  user-select: none;
  margin-top: 1px;
  max-height: 367px;
  overflow-y: auto;
  position: absolute;
  box-shadow: var(--box-shadow-spf);
  width: 100%;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  transition: none;
  z-index: 7;
}
.Options.Visible {
  display: block;
}
.Options.OpenAbove {
  box-shadow: var(--select-box-shadow-above);
  border-radius: 0;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.NoOption {
  line-height: 1.5em;
  border: 1px solid var(--border-2);
  border-top-color: transparent;
  color: var(--text-1);
  background-color: white;
  word-break: break-word;
  transition: none;
  padding: 6px 16px;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.Option {
  cursor: pointer;
  line-height: 1.5em;
  border: 1px solid var(--border-2);
  border-top-color: transparent;
  color: var(--text-2);
  background-color: white;
  word-break: break-word;
  transition: none;
  padding: 6px 16px;
  overflow-x: hidden;
}
.Option:hover {
  background-color: var(--background-color-input-focus);
  color: var(--text-link);
  font-weight: 500;
}
.Options.OpenAbove > .Option {
  border-top: 1px solid var(--border-2);
  border-bottom-color: transparent;
}
.Options > .Option:last-child {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.Options.OpenAbove > .Option:last-child {
  border-radius: 0;
}
.Options.OpenAbove > .Option:first-child {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.Option.OptionSelected {
  font-weight: 500;
  color: var(--text-4);
  background-color: var(--background-orange);
  border-left: 1px solid var(--text-link-hover);
  border-right: 1px solid var(--text-link-hover);
}
.Option.AlignLeft {
  text-align: left;
}
.Option.AlignCenter {
  text-align: center;
}
.Option.AlignRight {
  text-align: right;
}
.ErrorMessage {
  color: var(--text-error);
  padding: 7px 0;
}
.SelectedValueOuterDisabled {
  background-color: var(--background-color-2);
}
.SelectedValueOuterDisabled {
  cursor: initial;
}
.SelectedValueOuterDisabled .SelectedValue {
  color: var(--text-1);
}
.SelectedValueOuterDisabled .MarkerArrow {
  border-color: var(--text-0);
}
.Header {
  background-color: var(--select-header-background) !important;
  font-weight: 500 !important;
  color: white !important;
}
.Header:hover {
  cursor: inherit;
  color: var(--text-2);
}

:root {
  --select-header-background: rgb(49 138 212);
  --select-box-shadow-below: 0 8px 6px -6px rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1) 0px -2px 6px -1px;
  --select-box-shadow-above: 0 -1px 5px -2px rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1) 0px -2px 6px -1px;
}
/* HIDE SCROLL BARS */
.Options {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* for Chrome, Safari and Opera */
.Options::-webkit-scrollbar {
  display: none;
}

/* MENU CLASSES */
.MenuOuter .SelectedValueOuter {
  border: 1px solid var(--border-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}
.MenuOuter .SelectedValueOuter:hover {
  border-color: var(--text-link-hover);
}
.MenuOuter .SelectedValueOuter:hover .SelectedValue {
  color: var(--text-4);
}
.MenuOuter .MarkerArrowOuter {
  display: none;
}
.MenuOuter .Options {
  margin-top: 34px;
  max-height: 40vh;
  box-shadow: var(--select-box-shadow-below);
  width: auto;
  border-radius: var(--border-radius);
  background-color: var(--background-color-1);
}
.MenuOuter .Option {
  cursor: pointer;
  line-height: 1.5em;
  border: 1px solid transparent;
  color: var(--text-2);
  background-color: var(--background-color-1);
  word-break: break-word;
  transition: none;
  padding: 10px 20px;
  overflow-x: hidden;
  border-radius: var(--border-radius) !important;
}
.MenuOuter .Option:first-child {
  border-top-color: var(--border-1);
}
.MenuOuter .Option:hover {
  background-color: white;
  border-color: var(--text-link-hover);
  color: var(--text-4);
}
.Description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--fs-small);
  color: var(--text-0);
}

@media screen and (max-width: 900px) {
  .Option {
    padding: 12px 16px;
  }
  .Options {
    max-height: 75vh;
  }
}
