/* START LAYOUT CLASSES */

.WebsiteContentAdminOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin-top: 61px;
  position: fixed;
  top: 0;
  left: var(--admin-side-menu-width);
  overflow: auto;
  height: calc(100vh - var(--website-header-height));
  width: calc(100vw - var(--admin-side-menu-width));
  /* for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.WebsiteContentAdminOuter::-webkit-scrollbar {
  display: none;
}
.WebsiteContentAdmin {
  width: 100%;
  padding: 34px;
  min-height: calc(100vh - 100px);
  /* max-width: calc(var(--website-max-width) - var(--admin-side-menu-width)); */
  max-width: var(--admin-max-width);
  margin: 0 auto;
}

/* START ADMIN CLASSES */

.HeaderAdminOuter {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: #fff;
  box-shadow: var(--box-shadow-spf);
}
.HeaderAdmin {
  width: 100%;
  max-width: calc(var(--admin-max-width) - var(--admin-side-menu-width));
  padding: 0 24px;
  border-bottom: var(--border-discret);
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: var(--website-header-height);
}

.SideMenu {
  width: var(--admin-side-menu-width);
  border-right: var(--border-discret);
  padding: 12px 0;
}

@media screen and (max-width: 900px) {
  .WebsiteContentAdminOuter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    margin-top: var(--website-header-height);
    width: 100%;
    left: 0;
  }
  .WebsiteContentAdmin {
    width: calc(100% - 32px);
    padding: 24px 0;
    margin: 0 auto;
    min-height: 300px;
    max-width: calc(100% - 32px);
  }
  /* END LAYOUT CLASSES */
  .HeaderAdminOuter {
    z-index: inherit;
  }
  .HeaderAdmin {
    max-width: 100%;
    padding: 0 16px;
    border-bottom: var(--border-discret);
    justify-content: flex-start;
  }
}
