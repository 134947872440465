.Label {
  color: var(--text-2);
  padding-top: 10px;
}
.Input {
  margin: 7px 0;
}
.Error {
  color: var(--text-error);
  padding-bottom: 10px;
}

.InputDark {
  margin: 7px 0;
  color: white;
  background-color: var(--background-color-7);
}
.InputDark:focus {
  color: white;
  background-color: var(--text-2);
  box-shadow: var(--box-shadow-06);
}
.InputDark:hover {
  color: white;
  background-color: var(--text-2);
  box-shadow: var(--box-shadow-06);
  border-color: transparent;
}
