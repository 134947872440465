body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* HIDE SCROLL BARS */
body {
  /* for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0;
  padding-inline-start: 0;
  line-height: 1.4;
  text-decoration: none;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
table {
  border-spacing: 0px;
}

.ClientLoader {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 70px);
  min-height: 175px;
}

.WebsiteContentClient {
  width: 100vw;
  margin: 0 auto;
  min-height: var(--website-content-min-height);
  /* max-width: var(--website-max-width); */
}

.DisplayNone {
  display: none;
}
.ErrorParagraph {
  color: var(--text-error);
}

.RouteTitle {
  font-size: var(--fs-4);
  color: var(--text-2);
  padding: 10px 0;
}
.Explanation {
  font-size: var(--fs-small);
  color: var(--text-0);
}

input,
textarea {
  border: none;
  outline: none;
  font: inherit;
  padding: 5px 12px;
  border-radius: var(--border-radius);
  color: var(--text-3);
  width: 100%;
  border: 1px solid var(--border-2);
  /* background-color: var(--background-color-0); */
}
input:focus,
textarea:focus {
  background-color: var(--background-color-input-focus);
  border-color: var(--text-2);
  box-shadow: var(--btn-box-shadow-active);
}
.RemoveInputBoxShadow input:focus,
.RemoveInputBoxShadow textarea:focus {
  background-color: inherit;
  border-color: inherit;
  box-shadow: inherit;
}
input:hover,
textarea:hover {
  border-color: var(--border-amz-orange);
  background-color: white;
}

.NavItemList {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1px;
  row-gap: 1px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  box-sizing: border-box;
  padding-inline-start: 0;
}
.NavItemClone {
  font-size: var(--fs-1);
  padding: 14px 10px 14px 32px;
  margin: 0;
  width: 100%;
  border-bottom: var(--border-discret);
  text-align: left;
}

.RowStartCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
}
.FullWidthBottomBorder {
  width: 100%;
  margin: 16px 0;
  border-bottom: var(--border-discret);
}

.PaddingVSmall {
  padding: 6px 0;
}
.PaddingV {
  padding: 14px 0;
}
.MarginVSmall {
  margin: 6px 0;
}
.MarginV {
  margin: 14px 0;
}
.PaddingTSmall {
  padding-top: 6px;
}
.PaddingT {
  padding-top: 14px;
}
.PaddingBSmall {
  padding-bottom: 6px;
}
.PaddingB {
  padding-bottom: 14px;
}
.Small {
  font-size: var(--fs-small);
}
.Link {
  color: var(--text-link);
  cursor: pointer;
}
.Link:hover {
  color: var(--text-link-hover);
  text-decoration: underline;
}
.Link.Active {
  text-decoration: underline;
}

:root {
  --website-header-height: 70px;
  --website-footer-height: 210px;
  --website-content-min-height: calc(100vh - 366px);
  /*  header: 70px  +
      footer: 140px +
      paddings */

  --website-max-width: 1200px;
  --website-content-margin-top: 120px;

  --admin-side-menu-width: 240px;
  --admin-max-width: 1440px;

  --text-white: #fff;
  --text-0: #9a9a9a;
  --text-1: #565656;
  --text-2: #272727;
  --text-3: #141414;
  --text-4: #000;

  --background-color-0: #fafafa;
  --background-color-1: #f7f7f7;
  --background-color-2: #efefef;
  --background-color-3: #dedede;
  --background-color-4: #bebebe;
  --background-color-5: #9a9a9a;
  --background-color-6: #6a6a6a;
  --background-color-7: #404040;

  --border-1: #dfdfdf;
  --border-2: #aeaeae;
  --border-3: #9a9a9a;
  --border-4: #7a7a7a;
  --border-5: #595959;
  --border-radius: 3px;
  --border-radius-big: 0.5rem;

  --text-blue-intense: #0066ff;
  --text-link: #0066c0;
  --text-link-hover: #e76200;
  --text-error: #e72e00;
  --text-price: #b12704;
  --text-green: #067d62 !important;
  --text-light-green: #aae2aa;
  --text-btn-blue: rgb(0, 116, 212);
  --text-btn-blue-light: rgb(21 145 248);

  --btn-light-yellow: rgba(255, 219, 64, 0.5);
  --btn-light-green: rgba(135, 237, 35, 0.5);

  --border-color-ui-elements: #adb1b8 #a2a6ac #8d9096;
  --border-color-button-gray: #ddd;
  --border-color-button-yellow: #cecece;
  --border-color-input: #ababab;
  --border-color-input-focus-mobile: #cbcbcb;
  --background-color-input-focus: #fef8f2;

  --box-shadow-amz-selected: 0 0 3px 2px rgb(228 121 17 / 50%);
  --orange: #f79830;
  --border-amz-orange: #e77600;
  --dark-orange: #c45500;
  --border-link: #44aaf5;
  --background-orange: #fef8f2;
  --border-tester: 2px dotted green;
  --border-discret: 1px solid var(--border-1);

  --btn-bgr-gray: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
  --btn-bgr-gray-hover: linear-gradient(to bottom, #ebedf0, #dde0e4);
  --btn-bgr-gray-active: linear-gradient(to bottom, #f7f8fa, #dde0e4);
  --btn-box-shadow: 0 1px 0 rgb(255 255 255 / 40%) inset;
  --btn-box-shadow-active: 0 1px 3px rgb(0 0 0 / 20%) inset;

  --box-shadow-relaxed: 0 0.2rem 0.5rem rgb(0 0 0 / 15%);
  --box-shadow-06: 0 8px 6px -6px rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1) 0px -2px 6px -1px;

  --fs-smaller: 0.75rem;
  --fs-small: 0.875rem;
  --fs-1: 1rem;
  --fs-2: 1.125rem;
  --fs-3: 1.25rem;
  --fs-4: 1.5rem;
  --fs-5: 2rem;
  --fs-6: 2.5rem;
  --fs-7: 3rem;

  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -1px var(--tw-shadow-color);
  --box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  --box-shadow-toolbar-top: rgba(0, 0, 0, 0.1) 0px 2px 4px -1px;
  --box-shadow-spf: 0 0 5px rgba(23, 24, 24, 0.05),
    0 1px 2px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  --popup-background: linear-gradient(
      247deg,
      rgba(255, 255, 255, 0),
      rgba(85, 180, 176, 0.17),
      rgba(255, 255, 255, 0) 67.47%
    ),
    linear-gradient(
      12deg,
      rgba(255, 255, 255, 0),
      rgba(239, 192, 80, 0.05),
      rgba(255, 255, 255, 0) 78%
    );
}
.GenericRowOfButtons {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 900px) {
  .GenericRowOfButtons {
    display: grid;
    grid-template-columns: 1fr;
  }
  :root {
    --website-max-width: 100vw;

    --website-header-height: 70px;
    --website-footer-height: 210px;
    --website-content-min-height: calc(100vh - 200px);
  }

  .WebsiteContentClient {
    width: 100vw;
    word-break: break-word !important;
  }

  .DesktopHalfWidth {
    width: 100%;
  }
}
