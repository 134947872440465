.Outer {
  position: fixed;
  left: 21px;
  bottom: 57px;
  width: 430px;
  z-index: 999;
  padding: 17px;
  text-align: center;
  color: white;
  background: rgba(23, 25, 28, 0.85);
  box-shadow: var(--box-shadow-06);
  border-radius: var(--border-radius-big);
}
.CookieLink {
  color: var(--background-color-4);
  cursor: pointer;
  padding: 4px;
}
.CookieLink:hover {
  color: var(--text-btn-blue);
  text-decoration: underline;
}
.CookieLink.Active {
  text-decoration: underline;
}

@media screen and (max-width: 900px) {
  .Outer {
    position: fixed;
    left: 16px;
    bottom: 32px;
    width: calc(100vw - 32px);
    border-radius: 12px;
  }
}
